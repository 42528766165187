<template>
	<div class="page">
		<Tables ref="xTable" :tableName="tableName" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading"
			:searchFormData="searchFormData" :showRefresh="true" :showSearch="true" @getList="getList" :showOutput="true"
			@clearSearch="clearSearch">
			<!--搜索自定义内容-->
			<!--			<el-form-item slot="search-item">-->
			<!--				<el-select v-model="searchFormData.device_id" filterable remote reserve-keyword clearable size="small" placeholder="按设备名称查询" :remote-method="remoteDevice" :loading="s2Loading">-->
			<!--					<el-option v-for="item in sDevice" :key="item.device_id" :label="item.device_name" :value="item.device_id">-->
			<!--					</el-option>-->
			<!--				</el-select>-->
			<!--			</el-form-item>-->
			<el-form-item slot="search-item" >
				<el-input v-model="searchFormData.order_no" size="small" style="width: 150px;" clearable placeholder="按订单号查询"></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.device_no" size="small" style="width: 120px;" clearable
					placeholder="设备编号"></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.phone" size="small" style="width: 150px;" clearable
					placeholder="按手机号查询"></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.group_name" size="small" style="width: 100px;" clearable
					placeholder="消费站点"></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select size="small" v-model="searchFormData.qk_type" style="width: 120px;" clearable placeholder="枪口类型">
					<el-option v-for="(item,index) in $deviceType" :label="item" :value="index" :key="index">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select size="small" v-model="searchFormData.city" style="width: 120px;" clearable placeholder="消费地区">
					<el-option v-for="(item,index) in $userArea" :label="item" :value="index" :key="index">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select size="small" v-model="searchFormData.pay_type" style="width: 120px;" clearable placeholder="支付类型">
					<el-option v-for="(item,index) in $payType" :key="index" :label="item" :value="index">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select size="small" v-model="searchFormData.order_ly" style="width: 120px;" clearable placeholder="支付来源">
					<el-option v-for="(item,index) in $paySource" v-if="index!='60'" :label="item" :value="index" :key="index">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-date-picker v-model="searchFormData.start_time" style="width: 240px;" clearable type="daterange" format="yyyy-MM-dd"
					value-format="yyyy-MM-dd" start-placeholder="开始充电时间" end-placeholder="结束充电时间" size="small">
				</el-date-picker>
			</el-form-item>
			<!--el-form-item slot="search-item">
				<el-input v-model="searchFormData.fans_id" size="small" style="width: 150px;" clearable
					placeholder="按用户ID查询"></el-input>
			</el-form-item>-->
			<!--<el-form-item slot="search-item">
				<el-select v-model="searchFormData.uid" filterable remote reserve-keyword clearable size="small"
					placeholder="按用户名查询" :remote-method="remoteFans" :loading="sLoading">
					<el-option v-for="item in sFans" :key="item.fans_id" :label="item.nickname" :value="item.fans_id">
					</el-option>
				</el-select>
			</el-form-item-->
			<el-form-item slot="search-item">
				<el-select v-model="searchFormData.order_status" style="width: 120px;" placeholder="订单状态" clearable size="small">
					<el-option label="进行中" :value="10">
					</el-option>
					<el-option label="已取消" :value="20">
					</el-option>
					<el-option label="已完成" :value="30">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select size="small" v-model="searchFormData.is_vip" style="width: 120px;" clearable placeholder="VIP状态">
					<el-option v-for="(item,index) in $userType" :label="item" :value="index" :key="index">
					</el-option>
				</el-select>
			</el-form-item>

			<!--表格内容-->
			<vxe-table-column slot="table-item" field="order_no" title="订单号" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="u_type_text" title="用户信息" align="center" min-width="100px">
				<template v-slot="{ row }">
					<span v-if="row.u_type==10">{{row.phone||''}}</span>
					<span v-if="row.u_type==20">电卡-{{row.cardno}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="device_id_text" title="消费设备" align="center" min-width="100px">
				<template v-slot="{ row }">
					<div v-if="!row.device_id">无</div>
					<div v-if="row.device_id">{{row.device_name}}</div>
					<div class="vxe-sub" v-if="row.device_id">编号:{{row.device_no}}-{{row.plug_num}}</div>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="city" title="消费地区" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="group_name" title="消费站点" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="pay_type_text" title="支付类型" align="center" min-width="80px">
				<template v-slot="{ row }">
					<!--span>{{types.pay_type[row.pay_type] + (row.cardno ? '-在线卡' : '')||'未知类型'}}</span-->
					<span>{{$payType[row.pay_type] ||'未知类型'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="pay_source" title="支付方式" align="center" min-width="80px">
				<template v-slot="{ row }">
					{{$paySource[row.order_ly]}}
					<!--span v-if="row.order_ly==10">微信小程序</span>
					<span v-if="row.order_ly==20">支付宝小程序</span>
					<span v-if="row.order_ly==50">微信APP</span>
					<span v-if="row.order_ly==60">支付宝APP</span-->
				</template>
			</vxe-table-column>
			<!--			<vxe-table-column slot="table-item" field="after_balance" title="账户余额" align="center" min-width="80px" />-->
			<vxe-table-column slot="table-item" field="order_price" title="订单金额" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="pay_price" title="实际付款金额" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="yh_price_text" title="优惠金额" align="center" min-width="40px" >
				<template v-slot="{ row }">
					<el-tooltip placement="top">
					  <div slot="content">
						  场站:{{row.yh_group}}<br/>
						  优惠券:{{row.yh_coupon}}<br/>
						  VIP:{{row.yh_vip}}<br/>
						  <template v-for="itm in row.ext_yh">
							{{ itm[1] }}:{{itm[0]}}<br/>
						  </template>
						</div>
					  <span>{{row.yh_price}}</span>
					</el-tooltip>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="pay_status_text" title="支付状态" align="center" min-width="80px">
				<template v-slot="{ row }">
					<span>{{types.pay_status[row.pay_status]||'未知状态'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="electric" title="实际用电量" align="center" width="60px" />
			<vxe-table-column slot="table-item" field="qk_type_text" title="枪口类型" align="center" width="60px" />
			<vxe-table-column slot="table-item" field="duration_text" title="实际时长" align="center" min-width="80px">
				<template v-slot="{ row }">
					<span>{{$common.secondToTime(row.duration)}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="start_time" title="充电开始时间" align="center" width="100px" />
			<vxe-table-column slot="table-item" field="end_time" title="充电结束时间" align="center" width="100px" />
			<!--vxe-table-column slot="table-item" field="pay_time" title="付款时间" align="center" width="100px" /-->
			<vxe-table-column slot="table-item" field="order_status_text" title="订单状态" align="center" min-width="80px">
				<template v-slot="{ row }">
					<span>{{types.order_status[row.order_status]||'未知状态'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="coupon_id" title="优惠券" align="center" width="50px" />
			<vxe-table-column slot="table-item" field="is_vip" title="是否VIP" align="center" width="50px" />
			<vxe-table-column slot="table-item" title="操作" align="center" width="230">
				<template v-slot="{ row }">
					<el-button size="small" plain @click="detailOne(row)" v-if="$hasAccess('user.order/view')">详情
					</el-button>
					<el-button size="small" plain @click="workOne(row)"
						v-if="row.order_status==30&&$hasAccess('user.order/workorder')">日志</el-button>
					<el-button size="small" plain @click="workOne(row)"
						v-if="row.order_status!=30&&$hasAccess('user.order/workorder')">记录</el-button>
					<el-button size="small" plain @click="refundOne(row)"
						v-if="row.u_type==10&&row.order_status==10&&$hasAccess('user.order/refund')">结束
					</el-button>
					<el-button size="small" plain @click="endRefund(row)" v-if="row.order_status==30&&$hasAccess('user.order/endrefund')">退款
					</el-button>
				</template>
			</vxe-table-column>
		</Tables>

		<!-- 详情 -->
		<el-dialog title="消费详情" :visible.sync="detailDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="1200px">
			<el-form ref="detailForm" label-width="140px" class="form" size="mini">
				<el-row>
					<el-col :xs="{span:24,offset:0}" :sm="{span:6,offset:1}">
						<!-- 订单信息 -->
						<div class="el-block">
							<div class="row-title">订单信息</div>
							<el-form-item label="订单号：">{{detailDialogFormData.order_no}}</el-form-item>
							<el-form-item label="订单状态：">
								{{types.order_status[detailDialogFormData.order_status]||'未知状态'}}</el-form-item>
							<el-form-item label="智能充电订单：">{{types.is_auto[detailDialogFormData.is_auto]||'未知'}}
							</el-form-item>
							<el-form-item label="订单金额：">{{detailDialogFormData.order_price}}元</el-form-item>
							<!-- <el-form-item label="下单时间：">{{detailDialogFormData.creat_time}}</el-form-item> -->
							<el-form-item label="订单时间：">{{detailDialogFormData.start_time}}
								-<br />{{detailDialogFormData.end_time}}</el-form-item>
							<el-form-item label="订单时长：">{{$common.secondToTime(detailDialogFormData.order_duration*60)}}
							</el-form-item>
							<el-form-item label="订单功率：">{{detailDialogFormData.order_power}}</el-form-item>
							<el-form-item label="实际充电平均功率：">{{detailDialogFormData.power}}</el-form-item>
							<el-form-item label="实际用电量：">{{detailDialogFormData.electric}}</el-form-item>
							<el-form-item label="实际时长：">{{$common.secondToTime(detailDialogFormData.duration)}}
							</el-form-item>
							<el-form-item label="账户余额：">{{detailDialogUserBalance}}元</el-form-item>
						</div>
					</el-col>
					<el-col :xs="{span:24,offset:0}" :sm="{span:6,offset:2}">
						<!-- 支付信息 -->
						<div class="el-block">
							<div class="row-title">支付信息</div>
							<el-form-item label="消费用户：">
								{{detailDialogFormData.u_type==10?'微信-'+(detailDialogFormData.nickname||'匿名用户'):'电卡-'+detailDialogFormData.cardno}}
							</el-form-item>
							<el-form-item label="支付状态：">{{types.pay_status[detailDialogFormData.pay_status]||'未知状态'}}
							</el-form-item>
							<el-form-item label="支付类型：">
								{{$payType[detailDialogFormData.pay_type] + (detailDialogFormData.cardno ? '-在线卡' : '')||'未知类型'}}
							</el-form-item>
							<el-form-item v-if="detailDialogFormData.cardno" label="刷卡卡号：">
								{{detailDialogFormData.cardno}}</el-form-item>
							<el-form-item label="实际支付金额：">{{detailDialogFormData.pay_price}}元</el-form-item>
							<el-form-item label="微信支付金额：">{{detailDialogFormData.wx_price}}元</el-form-item>
							<el-form-item label="支付时间：">{{detailDialogFormData.pay_time}}</el-form-item>
						</div>
						<!-- 设备信息 -->
						<div class="el-block">
							<div class="row-title">设备信息</div>
							<el-form-item label="设备名称：">
								{{detailDialogFormData.device_name}}<br />{{detailDialogFormData.device_no}}
							</el-form-item>
							<el-form-item label="插座序号：">
								{{detailDialogFormData.plug_num}}（ID：{{detailDialogFormData.plug_id}}）</el-form-item>
							<el-form-item label="异常原因：">
								{{types.unusual_status[detailDialogFormData.unusual_status]||'未知原因'}}</el-form-item>
						</div>
					</el-col>
					<el-col :xs="{span:24,offset:0}" :sm="{span:6,offset:2}">
						<!-- 分时计费 -->
						<div class="el-block">
							<div class="row-title">分时计费</div>
							<el-form-item v-for="(cc,index) in detailDialogFormData.ChargeDetails" :key="index" :label="cc.DetailStartTime.substr(11) + ' ~ ' + cc.DetailEndTime.substr(11)">电量{{ typeof(cc.DetailPower) == 'number'?cc.DetailPower.toFixed(4):cc.DetailPower }} 电{{cc.DetailElecMoney}} 服{{ cc.DetailSeviceMoney }}</el-form-item>
						</div>
						<!-- 优惠信息 -->
						<div class="el-block">
							<div class="row-title">优惠信息</div>
							<el-form-item label="消费优惠ID：">
								{{detailDialogFormData.gift_num?detailDialogFormData.gift_id:'无'}}</el-form-item>
							<el-form-item label="优惠数量：">{{detailDialogFormData.gift_num||0}}</el-form-item>
						</div>
						<!-- 代理信息 -->
						<div class="el-block">
							<div class="row-title">代理信息</div>
							<el-form-item label="代理商ID：">{{detailDialogFormData.agent_id||'无'}}</el-form-item>
							<el-form-item label="分销金额：">{{detailDialogFormData.share_price}}元</el-form-item>
							<el-form-item label="分销结算状态：">
								{{types.share_status[detailDialogFormData.share_status]||'未知状态'}}</el-form-item>
						</div>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>

		<!-- 日志 -->
		<el-dialog title="设备交互日志" :visible.sync="workDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" :fullscreen="true">
			<vxe-table border highlight-hover-row resizable align="center" :data="workDialogFormData">
				<vxe-table-column type="seq" title="#" width="60"></vxe-table-column>
				<vxe-table-column field="time" title="时间" width="160">
					<template v-slot="{ row }">
						<span>{{$common.stampToDatetime(row.time*1000)}}</span>
					</template>
				</vxe-table-column>
				<vxe-table-column field="describe" title="说明" align="left" min-width="300"></vxe-table-column>
				<vxe-table-column field="type" title="传输" width="130" :formatter="formatType"></vxe-table-column>
				<vxe-table-column field="model" title="指令编号" width="85"></vxe-table-column>
				<vxe-table-column field="content" title="源指令" width="340" align="left"></vxe-table-column>
			</vxe-table>
		</el-dialog>

		<!-- 退款 -->
		<el-dialog title="结束订单确认" :visible.sync="refundDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="450px">
			<el-form :model="refundDialogFormData" :rules="refundRules" size="mini" ref="refundForm" label-width="120px"
				class="form">
				<el-form-item label="订单号：">{{refundDialogFormOriData.order_no}}</el-form-item>
<!--				<el-form-item label="实际付款金额：">{{refundDialogFormOriData.pay_price}}元</el-form-item>-->
<!--				<el-form-item label="退款方式：" prop="refund_for">-->
<!--					<el-radio-group v-model="refundDialogFormData.refund_for">-->
<!--						<el-radio :label="10">钱包</el-radio>-->
<!--						<el-radio :label="30">现金</el-radio>-->
<!--						<el-radio :label="40">赠送金</el-radio>-->
<!--					</el-radio-group>-->
<!--				</el-form-item>-->
<!--				<el-form-item label="退款金额：" prop="refund_price">-->
<!--					<el-input-number v-model="refundDialogFormData.refund_price" size="small" style="width: 80%;"-->
<!--						controls-position="right" :min="0.01" :max="refundDialogFormOriData.pay_price" :step="1"-->
<!--						:precision="2">-->
<!--					</el-input-number>-->
<!--				</el-form-item>-->
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveRefund()"
					v-if="$hasAccess('user.order/refund')">确 认 结 束
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>
		<!-- 退款 -->
		<el-dialog title="退款订单确认" :visible.sync="endRefundDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="450px">
			<el-form :model="endRefundData" :rules="endRefundRules" size="mini" ref="endRefundForm" label-width="120px" class="form">
				<el-form-item label="订单号：">{{endRefundData.order_no}}</el-form-item>
				<el-form-item label="实际付款金额：">{{endRefundData.pay_price}}元</el-form-item>
				<el-form-item label="退款金额：" prop="refund_price">
					<el-input-number v-model="endRefundData.refund_price" size="small" style="width: 80%;" controls-position="right" :min="0.01" :max="endRefundData.pay_price" :step="1" :precision="2">
					</el-input-number>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveEndRefund()"
					v-if="$hasAccess('user.order/endrefund')">确 认 结 束
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Tables from '@/components/tables'
	export default {
		name: 'user-order-list',
		components: {
			Tables
		},
		data() {
			return {
				// 表格信息
				tableName: '消费列表',
				isLoading: false,
				tableData: [],
				totalPage: 0,
				searchFormData: {
					u_type: 10
				},
				types: {
					pay_status: {
						10: '未支付',
						20: '已支付',
						30: '退款',
						50: '无需支付'
					},
					// pay_type: {
					// 	10: '钱包',
					// 	20: '在线卡',
					// 	21: '在线卡',
					// 	30: '先付后充',
					// 	40: '赠送金',
					// 	50: '信用分',
					// 	60: '企业卡',
					// 	61: '电卡',
					// 	62: '数币支付'
					// },
					order_status: {
						10: '进行中',
						20: '已取消',
						30: '已完成'
					},
					unusual_status: {
						10: '正常',
						20: '功率异常',
						30: '插座脱落',
						40: '信号异常'
					},
					is_auto: {
						10: '是',
						20: '否'
					},
					share_status: {
						10: '不结算',
						20: '未结算',
						30: '已结算'
					}
				},
				// 用户搜索
				sLoading: false,
				sFans: [],
				// 设备搜索
				s2Loading: false,
				sDevice: [],
				// 详情
				detailDialogShow: false,
				detailDialogFormData: {},
				detailDialogUserBalance: 0,
				// 日志
				workDialogShow: false,
				workDialogFormData: {},
				// 退款
				refundDialogShow: false,
				refundDialogFormData: {},
				refundDialogFormOriData: {},
				refundRules: {
					refund_price: [{
						required: true,
						message: '请填写退款金额',
						trigger: 'change'
					}],
					refund_for: [{
						required: true,
						message: '请选择退款方式',
						trigger: 'change'
					}]
				},
				//已结束订单退款
				endRefundDialogShow: false,
				endRefundData:{},
				endRefundRules:{
					refund_price: [{
						required: true,
						message: '请填写退款金额',
						trigger: 'change'
					}],
				},
			}
		},
		mounted() {
			this.$refs.xTable.exportTable = () => {
				if(this.totalPage + 1 > 5000){
					return this.$notify({title: '失败', message: '导出数据一次最多5000条', type: 'warming'});
				}
				const params = {
					token: this.$store.state.user.token,
					page: 1,
					size: this.totalPage + 1
				}
				const searchData = this.searchFormData
				// 小区限制
				const group_id = this.$store.state.user.userInfo.group_id
				if (!searchData.group_id && group_id) {
					searchData.group_id = group_id
				}
				this.$api.Fans.GetOrderList(params, searchData).then(res => {
					for(let v of res.data){
						v.pay_status_text = this.types.pay_status[v.pay_status];
						if(v.u_type == 10){
							v.u_type_text = v.phone||'';
						}else if(v.u_type == 20){
							v.u_type_text = '电卡-' + v.cardno;
						}
						if(!v.device_id){
							v.device_id_text = '无';
						}else{
							v.device_id_text = v.device_name;
							v.device_id_text += ' 编号:' + v.device_no + '-' + v.plug_num;
						}
						v.pay_type_text = this.$payType[v.pay_type] + (v.cardno ? '-在线卡' : '')||'未知类型';
						v.pay_source = this.$paySource[v.order_ly];
						v.yh_price_text = (v.yh_price||0) + ' 场站:' + (v.yh_group||0) + ' 优惠券:'+ (v.yh_coupon||0) + ' VIP:' + (v.yh_vip||0);
						if(v.ext_yh){
							for(let yy of v.ext_yh){
								v.yh_price_text += " " + yy[1] + ":" + yy[0];
							}
						}
						v.duration_text = this.$common.secondToTime(v.duration);
						v.qk_type_text = this.$deviceType[v.qk_type];
						v.order_status_text = this.types.order_status[v.order_status]||'未知状态';
					}
					this.$refs.xTable.exportTableAll(res.data)
				}).catch(err => {});
			}
		},
		methods: {
			// 表格列表
			async getList(currentPage, pageSize) {
				const params = {
					token: this.$store.state.user.token,
					page: currentPage,
					size: pageSize
				}
				this.isLoading = true
				const searchData = this.searchFormData
				// 小区限制
				const group_id = this.$store.state.user.userInfo.group_id
				if (!searchData.group_id && group_id) {
					searchData.group_id = group_id
				}
				const res = await this.$api.Fans.GetOrderList(params, searchData)
				this.tableData = res.data;
				for(let v of this.tableData){
					v.pay_status_text = this.types.pay_status[v.pay_status];
					if(v.u_type == 10){
						v.u_type_text = v.phone||'';
					}else if(v.u_type == 20){
						v.u_type_text = '电卡-' + v.cardno;
					}
					if(!v.device_id){
						v.device_id_text = '无';
					}else if(v.device_id){
						v.device_id_text = v.device_name;
					}else{
						v.device_id_text = '编号:' + v.device_no + '-' + v.plug_num;
					}
					v.pay_type_text = this.$payType[v.pay_type] + (v.cardno)||'未知类型';
					v.pay_source = this.$paySource[v.order_ly];
					v.yh_price_text = (v.yh_price||0) + ' 场站:' + (v.yh_group||0) + ' 优惠券:'+ (v.yh_coupon||0) + ' VIP:' + (v.yh_vip||0);
					v.duration_text = this.$common.secondToTime(v.duration);
					v.qk_type_text = this.$deviceType[v.qk_type];
				}
				this.totalPage = res.total
				this.isLoading = false
			},
			// 重置搜索
			clearSearch() {
				this.searchFormData = {
					u_type: 10
				}
			},
			// 关闭弹窗
			closeDialog() {
				this.detailDialogShow = false
				this.refundDialogShow = false;
				this.endRefundDialogShow = false;
			},
			// 详情
			async detailOne(row) {
				const params = {
					token: this.$store.state.user.token,
					order_id: row.order_id
				}
				const res = await this.$api.Fans.GetOrderDetail(params);
				if(typeof(res.ChargeDetails) == 'string') res.ChargeDetails = JSON.parse(res.ChargeDetails);
				this.detailDialogFormData = res
				this.detailUserOne(res.uid)
				this.detailDialogShow = true
			},
			// 用户详情
			async detailUserOne(id) {
				const params = {
					token: this.$store.state.user.token,
					fans_id: id
				}
				const res = await this.$api.Fans.GetFansDetail(params)
				this.detailDialogUserBalance = res.balance
			},
			// 日志
			async workOne(row) {
				this.isLoading = true;
				const params = {
					token: this.$store.state.user.token,
					order_id: row.order_id
				}
				const res = await this.$api.Fans.GetWorkOrder(params)
				this.workDialogFormData = res.log_content
				this.workDialogShow = true
				this.isLoading = false;
			},
			// 列表格式化
			formatType({
				cellValue
			}) {
				if (cellValue == 'server') {
					return '服务器 => 硬件'
				}
				if (cellValue == 'client') {
					return '硬件 => 服务器'
				}
				return '未知'
			},
			// 退款
			refundOne(row) {
				this.refundDialogFormData = {
					order_id: row.order_id,
					refund_price: row.pay_price,
					refund_for: 40
				}
				this.refundDialogFormOriData = {
					pay_price: Number(row.pay_price),
					pay_status: row.pay_status,
					order_no: row.order_no
				}
				this.refundDialogShow = true
			},
			// 已结束订单退款
			endRefund(row) {
				this.endRefundData = {
					pay_price: Number(row.pay_price),
					pay_status: row.pay_status,
					order_no: row.order_no,
					order_id: row.order_id,
				}
				this.endRefundDialogShow = true
			},
			// 已结束订单退款
			saveEndRefund(row) {
				this.$refs.endRefundForm.validate(async (valid) => {
					if (valid) {
						this.closeDialog()
						this.isLoading = true;
						const params = Object.assign({token: this.$store.state.user.token}, this.endRefundData);
						await this.$api.Fans.EndRefund(params)
						this.$notify({
							title: '成功',
							message: '退款成功',
							type: 'success'
						})
						this.closeDialog()
						this.$refs.xTable.refreshTable()
					}
				})
			},
			// 退款保存
			saveRefund() {
				this.$refs.refundForm.validate(async (valid) => {
					if (valid) {
						this.closeDialog()
						this.isLoading = true;
						const params = {
							token: this.$store.state.user.token,
							form: JSON.stringify(this.refundDialogFormData)
						}
						await this.$api.Fans.RefundOrder(params)
						this.$notify({
							title: '成功',
							message: '退款成功',
							type: 'success'
						})
						this.closeDialog()
						this.$refs.xTable.refreshTable()
					}
				})
			},
			// 用户名查询
			async remoteFans(query) {
				if (query !== '') {
					this.sLoading = true
					const params = {
						token: this.$store.state.user.token
					}
					const searchData = {
						nickname: query
					}
					const res = await this.$api.Fans.GetFansList(params, searchData)
					this.sLoading = false
					this.sFans = res.data
				} else {
					this.sFans = []
				}
			},
			// 设备名查询
			async remoteDevice(query) {
				if (query !== '') {
					this.s2Loading = true
					const params = {
						token: this.$store.state.user.token
					}
					const searchData = {
						device_name: query
					}
					const res = await this.$api.Device.GetDeviceList(params, searchData)
					this.s2Loading = false
					this.sDevice = res.data
				} else {
					this.sDevice = []
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.row-title {
		font-size: 14px;
		line-height: 20px;
		padding: 4px 0;
		margin-bottom: 18px;

		&:before {
			content: " ";
			width: 5px;
			height: 20px;
			background: #008e4d;
			display: block;
			float: left;
			margin: 0 10px 0 0;
		}
	}
</style>
